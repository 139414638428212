import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import discountLottie from "../../images/lottie/discount.json"
import { useGetActiveCampaignsQuery } from '../../features/coupon/couponAPI';
import { useDispatch } from 'react-redux';
import { openCampaignModal } from '../../features/coupon/couponSlice';
import fireLottie from "../../images/lottie/fire.json";
import useActiveCampaign from './useActiveCampaign';

function DiscountPromoNavbar() {
    const { campaign, handleCampaignHide, handleCampaignClick } = useActiveCampaign({
        type: "nav"
    });
    if (!campaign) {
        return null;
    }
    return (
        <div className='relative flex group/item flex-col items-center cursor-pointer' onClick={handleCampaignClick}>
            <div className='absolute top-0 right-2 text-blue-500 hover:scale-125 opacity-0 group-hover/item:opacity-100' onClick={handleCampaignHide}><svg width="16" height="16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_40819)">
                    <path d="M16 30C12.287 30 8.72601 28.525 6.1005 25.8995C3.475 23.274 2 19.713 2 16C2 12.287 3.475 8.72601 6.1005 6.1005C8.72601 3.475 12.287 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30ZM16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3131 32 20.2435 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0C11.7565 0 7.68687 1.68571 4.68629 4.68629C1.68571 7.68687 0 11.7565 0 16C0 20.2435 1.68571 24.3131 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32Z" fill="currentColor" />
                    <path d="M9.29183 9.29379C9.38473 9.20066 9.49508 9.12677 9.61657 9.07636C9.73806 9.02595 9.8683 9 9.99983 9C10.1314 9 10.2616 9.02595 10.3831 9.07636C10.5046 9.12677 10.6149 9.20066 10.7078 9.29379L15.9998 14.5878L21.2918 9.29379C21.3848 9.20081 21.4952 9.12706 21.6167 9.07674C21.7381 9.02642 21.8683 9.00052 21.9998 9.00052C22.1313 9.00052 22.2615 9.02642 22.383 9.07674C22.5045 9.12706 22.6149 9.20081 22.7078 9.29379C22.8008 9.38676 22.8746 9.49714 22.9249 9.61862C22.9752 9.7401 23.0011 9.8703 23.0011 10.0018C23.0011 10.1333 22.9752 10.2635 22.9249 10.385C22.8746 10.5064 22.8008 10.6168 22.7078 10.7098L17.4138 16.0018L22.7078 21.2938C22.8008 21.3868 22.8746 21.4971 22.9249 21.6186C22.9752 21.7401 23.0011 21.8703 23.0011 22.0018C23.0011 22.1333 22.9752 22.2635 22.9249 22.385C22.8746 22.5064 22.8008 22.6168 22.7078 22.7098C22.6149 22.8028 22.5045 22.8765 22.383 22.9268C22.2615 22.9772 22.1313 23.003 21.9998 23.003C21.8683 23.003 21.7381 22.9772 21.6167 22.9268C21.4952 22.8765 21.3848 22.8028 21.2918 22.7098L15.9998 17.4158L10.7078 22.7098C10.6149 22.8028 10.5045 22.8765 10.383 22.9268C10.2615 22.9772 10.1313 23.003 9.99983 23.003C9.86835 23.003 9.73815 22.9772 9.61667 22.9268C9.49519 22.8765 9.38481 22.8028 9.29183 22.7098C9.19886 22.6168 9.12511 22.5064 9.07479 22.385C9.02447 22.2635 8.99857 22.1333 8.99857 22.0018C8.99857 21.8703 9.02447 21.7401 9.07479 21.6186C9.12511 21.4971 9.19886 21.3868 9.29183 21.2938L14.5858 16.0018L9.29183 10.7098C9.19871 10.6169 9.12482 10.5065 9.07441 10.3851C9.024 10.2636 8.99805 10.1333 8.99805 10.0018C8.99805 9.87025 9.024 9.74001 9.07441 9.61852C9.12482 9.49703 9.19871 9.38668 9.29183 9.29379Z" fill="currentColor" />
                </g>
            </svg>
            </div>
            <Lottie
                animationData={discountLottie}
                loop={true}
                className="w-[32px] sm:w-[45px] h-auto group-hover/item:scale-125 transition-all ease-in-out"
            />
            <div className='font-[poppins] font-light text-xs text-center w-[120px] leading-none'>
                {campaign.title}
            </div>
        </div>
    )
}
const DiscountPromoBanner = () => {
    const { campaign, handleCampaignHide, handleCampaignClick } = useActiveCampaign({
        type: "banner"
    });
    if (!campaign) {
        return null;
    }
    return <div className='font-[poppins] w-full z-[1000] bg-gradient-to-r from-[#c00a0a] to-[#a30100] text-white text-sm  transition-all duration-300 z-21 relative'>
        <div className='container max-w-screen-xl flex flex-col lg:flex-row items-center justify-between mx-auto px-3 sm:px-16 py-2.5 text-center'>
            <p className=''>
                <Lottie
                    animationData={fireLottie}
                    loop={true}
                    className="h-[24px] inline-block translate-y-[1px] mr-0.5"
                />
                <strong className='font-bold text-lg text-white align-baseline'>{campaign.title}</strong>
            </p>
            <div className='flex flex-col gap-1 lg:flex-row lg:gap-6 items-center flex-wrap'>
                <strong className='font-bold text-lg text-white align-bottom mr-6'>Track more, save more!</strong>
                <button
                    className="uppercase bg-[#ffc323] font-medium text-base text-white py-1.5 px-5 rounded-lg hover:bg-black hover:text-white"
                    onClick={handleCampaignClick}
                >
                    Upgrade now

                </button>
            </div>

        </div>
        <div className='cursor-pointer text-white absolute right-5 -translate-y-1/2 top-1/2' onClick={handleCampaignHide}>
            <svg xmlns="http://www.w3.org/2000/svg" width={"22px"} height={"22px"} viewBox="0 0 50 50"><path fill="currentColor" d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" /></svg>
        </div>
    </div>
}
export {
    DiscountPromoNavbar,
    DiscountPromoBanner
}