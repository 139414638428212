import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import ErrorScreen from '../Error/ErrorScreen';

const Layout = ({ children }) => {
  const location = useLocation();
  const isChatRoute = location.pathname === '/chat';
  return (
    <div className={`${isChatRoute ? 'w-full h-screen flex flex-col overflow-hidden' : ''}`}>
      <Navbar />
      {isChatRoute ? (
        <ErrorBoundary FallbackComponent={ErrorScreen} key={location.pathname}>
          {children}
        </ErrorBoundary>
      ) : (
        <div
          className={`overflow-y-auto`}
        >
          <ErrorBoundary
            FallbackComponent={ErrorScreen}
            key={location.pathname}
          >
            {children}
          </ErrorBoundary>
        </div>
      )}
      {location.pathname !== '/utm' && (
      <Footer />
      )}
    </div>
  );
};


// HOC to wrap the component with Layout
export const withLayout = (Component) => (props) => (
  <Layout>
    <Component {...props} />
  </Layout>
);
export default Layout;
