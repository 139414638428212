import React from 'react';
import Header from './Header';
import Features from './Features';
import SplitColumns from './SplitColumns';
import Compare from './Compare';
import WhyUs from './WhyUs';
import Contact from './Contact';
import SupportedFormsVisuals from './SupportedFormsVisuals';
import Prices from './Prices';
import Faq from './FAQ';
import Trusted from './Trusted';
import Schedule from './Schedule';
import Landing from './Landing';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { withLayout } from './Layout';

function Home() {
  const location = useLocation();
  const previousHash = useRef('');
  useEffect(() => {
    if (location.hash) {
      let element = null;
      const urlSplits = location.hash.substring(1).split('?');

      if (urlSplits.length > 1) {
        previousHash.current = urlSplits[0]
        element = document.getElementById(urlSplits[0]);
      } else {
        previousHash.current = location.hash.substring(1)
        element = document.getElementById(location.hash.substring(1));
      }

      if (previousHash.current && element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          previousHash.current = '';
        }, 500);
      }
    } else {
      // window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className='relative'>
      <Header />
      <Trusted />
      <Compare />
      <Features />
      <Landing />
      <Prices />
      <SupportedFormsVisuals />
      <WhyUs />
      <Schedule />
      <Faq />
      <SplitColumns />
      <Contact />
    </div>
  );
}
export default withLayout(Home);