import React from 'react';
// import { useDispatch } from "react-redux";
// import { setStoreData } from "../../store/auth";
import { Link, useNavigate } from 'react-router-dom';

// const location = window.location;

const Footer = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <footer className='bg-[#ececec] z-20'>
      <div className='container mx-auto'>
        <ul className='flex justify-center space-x-[15px] pt-[30px] mb-[10px]'>
          <li className='wow fadeInUp'>
            <a
              target='_blank'
              href='https://www.facebook.com/handlutmgrabber'
              rel='noreferrer'
            >
              <i className='fa fa-facebook w-[48px] h-[48px] text-white bg-[#1972f5] rounded-full text-[22px] flex justify-center items-center'></i>
            </a>
          </li>
          <li className='wow fadeInUp' data-wow-delay='0.1s'>
            <a
              target='_blank'
              href='https://twitter.com/UTMGrabber'
              rel='noreferrer'
            >
              <i className='fa fa-twitter w-[48px] h-[48px] text-white bg-[#1972f5] rounded-full text-[22px] flex justify-center items-center'></i>
            </a>
          </li>
          <li className='wow fadeInUp' data-wow-delay='0.2s'>
            <a
              target='_blank'
              href='https://www.instagram.com/utmgrabber/'
              rel='noreferrer'
            >
              <i className='fa fa-instagram w-[48px] h-[48px] text-white bg-[#1972f5] rounded-full text-[22px] flex justify-center items-center'></i>
            </a>
          </li>
          <li className='wow fadeInUp' data-wow-delay='0.3s'>
            <a
              target='_blank'
              href='https://www.youtube.com/channel/UCKBJZALjt4isMCfa8-2bnng'
              rel='noreferrer'
            >
              <i className='fa fa-youtube-play w-[48px] h-[48px] text-white bg-[#1972f5] rounded-full text-[22px] flex justify-center items-center'></i>
            </a>
          </li>
        </ul>
        <div className='row m0 text-center'>
          <ul className='nav footer-menu'>
            <li>
              <a href='/terms'>Terms of Use</a>
            </li>
            <li>
              <a href='/privacy'>Privacy Policy</a>
            </li>
            <li>
              <Link to='/disclaimer'>Disclaimer</Link>
              {/* <a
                className='cursor-pointer'
                // href='#'
                onClick={() =>
                  // dispatch(
                  //   setStoreData({
                  //     data: {
                  //       showDisclaimer: true,
                  //     },
                  //   })
                  // )
                  navigate('/disclaimer')
                }
              >
                Disclaimer
              </a> */}
            </li>
          </ul>
          <p className='footer-address'>
            10900 Research Blvd, Ste 160C, Austin, TX 78759 &bull; +1 512 666
            4243
          </p>
          <p>
            Copyright &copy; {new Date().getFullYear()}. HandL Digital LLC{' '}
            <br className='small-divide' /> All rights reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
