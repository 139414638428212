import React, { useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { closeCampaignModal, setActiveCoupon } from '../../features/coupon/couponSlice';
import { useNavigate } from 'react-router-dom';
import { useValidateCouponMutation } from '../../features/coupon/couponAPI';

function CampaignPromo() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const activeCampaign = useSelector((state) => state.coupon.activeCampaign);
    const [timeLeft, setTimeLeft] = useState(0);

    useEffect(() => {
        if (!activeCampaign || !activeCampaign.date_end) {
            return;
        }

        const calculateTimeLeft = () => {
            const now = new Date();
            const untilDate = new Date(activeCampaign.date_end);
            const difference = untilDate - now;
            return Math.max(Math.floor(difference / 1000), 0);
        };

        setTimeLeft(calculateTimeLeft());

        const timer = setInterval(() => {
            const remainingTime = calculateTimeLeft();
            setTimeLeft(remainingTime);
            if (remainingTime <= 0) {
                clearInterval(timer);
                dispatch(closeCampaignModal());
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [activeCampaign, dispatch]);
    const [validateCouponMutation, {
        isLoading: couponLoading,
    }] = useValidateCouponMutation();
    const onUpgradeNow = async () => {
        const couponData = await validateCouponMutation({
            coupon: activeCampaign?.coupon_stripe,
        }).unwrap();
        // Get the coupon details
        dispatch(setActiveCoupon({
            couponStripe: activeCampaign?.coupon_stripe,
            coupon: couponData
        }));

        dispatch(closeCampaignModal());
        navigate('/#prices');
    };

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (60 * 60 * 24));
        const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((seconds % (60 * 60)) / 60);
        const remainingSeconds = seconds % 60;

        if (days > 0) {
            return `${days}d ${hours}h ${minutes}m ${remainingSeconds}s`;
        } else if (hours > 0) {
            return `${hours}h ${minutes}m ${remainingSeconds}s`;
        } else if (minutes > 0) {
            return `${minutes}m ${remainingSeconds}s`;
        } else {
            return `${remainingSeconds}s`;
        }
    };

    if (!activeCampaign) {
        return null;
    }

    return (
        <Modal isOpen={Boolean(activeCampaign)} onClose={() => dispatch(closeCampaignModal())}>
            <div className="bg-white p-6 rounded-lg text-center shadow-lg max-w-xs mx-auto font-[poppins]">
                <h2 className="text-xl font-semibold mb-2">{activeCampaign.title}</h2>
                <p className="mb-2 font-normal text-sm">{activeCampaign.body}</p>
                <p className="mb-1 font-normal text-sm">Offer valid for a limited time – track more, save more!</p>
                {activeCampaign.date_end && (
                    <p className="text-red-500 text-2xl font-bold mb-2">{formatTime(timeLeft)}</p>
                )}
                <button
                    className="bg-red-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-600"
                    onClick={onUpgradeNow}
                >
                    Upgrade now  {couponLoading ? <i className="ml-1 fa fa-spinner fa-spin"></i> :
                        null}

                </button>
            </div>
        </Modal>
    );
}

export default CampaignPromo;
