import React, { useEffect, useState } from 'react';
import Logo from '../../images/logo.png';
import Logo2x from '../../images/logo@2x.png';
import LogoW from '../../images/logo.webp';
import LogoW2x from '../../images/logo@2x.webp';
import { Auth } from 'aws-amplify';
import { removeQString } from '../../js/params';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreData } from '../../store/auth';
import { Link, useNavigate } from 'react-router-dom';
import { DiscountPromoBanner, DiscountPromoNavbar } from '../PayFlow/DiscountPromo';
const Navbar = ({ className = "" }) => {
  const [state, setState] = useState({
    isScrolled: false,
  });

  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [isShowNav, setIsShowNav] = useState(false);

  const dispatch = useDispatch();
  const { isAuthenticated, tmpUser } = useSelector(
    (state) => state.auth.data || {}
  );

  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setState({
      ...state,
      isScrolled: scrolled ? true : false,
    });
  };

  const onLogout = async (event) => {
    event.preventDefault();
    localStorage.removeItem('tmpUser');
    try {
      await Auth.signOut();
      dispatch(
        setStoreData({
          data: {
            isAuthenticated: false,
            tmpUser: null,
          },
        })
      );
      window.location = removeQString('login');
    } catch (error) {
      dispatch(
        setStoreData({
          data: {
            isAuthenticated: false,
            tmpUser: null,
          },
        })
      );
      console.log(error.message);
    }
  };

  const onLogin = (event) => {
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          showLogin: true,
        },
      })
    );
  };

  return (
    <div className='sticky top-0 z-[100]'>
      <DiscountPromoBanner />
      <nav
        className={`navbar w-full bg-white z-[100] transition transition-all ${state.isScrolled
          ? 'pt-[10px] drop-shadow-xl'
          : 'pt-[22px]'
          } ${className}`}
      >
        <div className='container max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-2 sm:px-12'>
          <div>
            <div
              className='navbar-brand cursor-pointer'
              onClick={() => navigate('/')}
            >
              <picture>
                <source srcset={`${LogoW} 1x, ${LogoW2x} 2x`} type="image/webp" />
                <img src={Logo} alt='HandL UTM Grabber Logo' srcset={`${Logo2x} 2x`} className={`${state.isScrolled ? 'w-[150px]' : 'w-[180px]'
                  } h-auto transition transition-all`} />
              </picture>
            </div>
          </div>
          <DiscountPromoNavbar />
          <button
            data-collapse-toggle='navbar-default'
            type='button'
            className='inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600'
            aria-controls='navbar-default'
            aria-expanded='false'
            onClick={() => setIsShowNav(!isShowNav)}
          >
            <span className='sr-only'>Open main menu</span>
            <svg
              className='w-6 h-6'
              aria-hidden='true'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                clipRule='evenodd'
              ></path>
            </svg>
          </button>

          <div className='hidden w-full lg:w-auto lg:block' id='navbar-default'>
            <ul className='font-medium flex flex-col p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg lg:flex-row lg:space-x-8 lg:mt-0 lg:border-0 '>
              <li>
                {/* <a
                target='_blank'
                rel='noreferrer'
                href='https://utmgrabber.com/utm/'
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
              >
                UTM?
              </a> */}
                <Link to='/utm' className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'>UTM?</Link>
              </li>
              <li>
                <button
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  onClick={(e) => {
                    navigate('/#features');
                  }}
                >
                  Features
                </button>
              </li>
              {!isAuthenticated && tmpUser == null && (
                <li>
                  <button
                    className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                    onClick={(e) => {
                      e.preventDefault();
                      console.log('are we fit');
                      // $.magnificPopup.close();
                      dispatch(setStoreData({ data: { showAreWeFit: true } }));
                    }}
                  >
                    Are We Fit?
                  </button>
                </li>
              )}
              <li>
                <button
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  onClick={() => {
                    navigate('/#prices');
                  }}
                >
                  Pricing
                </button>
              </li>
              {/*<li><a href="#why">Reviews</a></li>*/}
              {/*<li><a href="#faq">FAQ</a></li>*/}
              <li>
                <button
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  onClick={(e) => {
                    navigate('/#schedule-expert');
                  }}
                >
                  Demo
                </button>
              </li>
              <li>
                <button
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  onClick={() => {
                    navigate('/#contact');
                  }}
                >
                  Contact
                </button>
              </li>
              {isAuthenticated && tmpUser != null && (
                <li>
                  <a
                    target='_blank'
                    href='https://docs.utmgrabber.com/'
                    className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                    rel='noreferrer'
                  >
                    Docs
                  </a>
                </li>
              )}

              {!isAuthenticated && (
                <li>
                  <button
                    onClick={onLogin}
                    className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  >
                    Docs
                  </button>
                </li>
              )}
              {isAuthenticated && tmpUser != null && (
                <li
                  className='dropdown relative'
                  onMouseEnter={() => setIsShowDropDown(true)}
                  onMouseLeave={() => setIsShowDropDown(false)}
                >
                  <button
                    className='dropdown-toggle text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                    data-bs-toggle='dropdown'
                  >
                    Hello, {tmpUser.first_name} <span className='caret'></span>
                  </button>
                  <ul
                    className={`dropdown-menu absolute left-0 bg-white px-3 w-full ${isShowDropDown ? 'block' : 'hidden'
                      }`}
                  >
                    <li>
                      <Link to='/infos' className='text-nowrap overflow-visible'>My Account</Link>
                    </li>
                    <li>
                      <Link to='/apps'>Apps</Link>
                    </li>
                    <li>
                      <button onClick={onLogout}>Logout</button>
                    </li>
                  </ul>
                </li>
              )}

              {!isAuthenticated && (
                <li>
                  <button
                    href=''
                    onClick={onLogin}
                    className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  >
                    Login
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div
          className={`${isShowNav ? 'block' : 'hidden'} w-full md:w-auto`}
          id='navbar-default'
        >
          <ul className='font-medium flex flex-col p-4 lg:p-0 pl-[48px] mt-4 border-gray-100 rounded-lg lg:flex-row lg:space-x-8 lg:mt-0'>
            <li>
              <button
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  navigate('/utm');
                }}
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
              >
                UTM?
              </button>
            </li>
            <li>
              <button
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                onClick={() => {
                  navigate('/#features');
                }}
              >
                Features
              </button>
            </li>
            {!isAuthenticated && tmpUser == null && (
              <li>
                <button
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  onClick={(e) => {
                    e.preventDefault();
                    console.log('are we fit');
                    // $.magnificPopup.close();
                    dispatch(setStoreData({ data: { showAreWeFit: true } }));
                  }}
                >
                  Are We Fit?
                </button>
              </li>
            )}
            <li>
              <button
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                onClick={(e) => {
                  navigate('/#prices');
                }}
              >
                Pricing
              </button>
            </li>
            {/*<li><a href="#why">Reviews</a></li>*/}
            {/*<li><a href="#faq">FAQ</a></li>*/}
            <li>
              <button
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                onClick={(e) => {
                  navigate('/#schedule-expert');
                }}
              >
                Demo
              </button>
            </li>
            <li>
              <button
                className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                onClick={() => {
                  navigate('/#contact');
                }}
              >
                Contact
              </button>
            </li>
            {isAuthenticated && tmpUser != null && (
              <li>
                <a
                  target='_blank'
                  href='https://docs.utmgrabber.com/'
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  rel='noreferrer'
                >
                  Docs
                </a>
              </li>
            )}

            {!isAuthenticated && (
              <li>
                <button
                  onClick={onLogin}
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                >
                  Docs
                </button>
              </li>
            )}
            {isAuthenticated && tmpUser != null && (
              <li
                className='dropdown relative'
                onMouseEnter={() => setIsShowDropDown(true)}
                onMouseLeave={() => setIsShowDropDown(false)}
              >
                <button
                  className='dropdown-toggle text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                  data-bs-toggle='dropdown'
                >
                  Hello, {tmpUser.first_name} <span className='caret'></span>
                </button>
                <ul
                  className={`dropdown-menu absolute bottom-[-110px] left-0 bg-white px-3 ${isShowDropDown ? 'block' : 'hidden'
                    }`}
                >
                  <li>
                    <Link to='/infos' className='text-nowrap overflow-visible'>My Account</Link>
                  </li>
                  <li>
                    <Link to='/infos/downloads'>Downloads</Link>
                  </li>
                  <li>
                    <Link to='/apps'>Apps</Link>
                  </li>
                  <li>
                    <button onClick={onLogout}>Logout</button>
                  </li>
                </ul>
              </li>
            )}

            {!isAuthenticated && (
              <li>
                <button
                  onClick={onLogin}
                  className='text-[16px] leading-[40px] font-[Poppins] text-[#8f969d] font-[500] uppercase'
                >
                  Login
                </button>
              </li>
            )}
          </ul>
        </div>
      </nav>

    </div>
  );
};

export default Navbar;
