import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { API } from '@aws-amplify/api';
import { ToastContainer, toast } from 'react-toastify';
import { Popover } from '@headlessui/react';

// A component to display the status of the plan
const PlanStatus = ({ status }) => {
  if (status == 'active' || status == 'trialing') {
    return (
      <div className='inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-emerald-500 bg-emerald-100/60'>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10 3L4.5 8.5L2 6'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <h2 className='text-sm font-normal'>Active</h2>
      </div>
    );
  } else if (status == 'canceled' || status == 'cancelled') {
    return (
      <div className='inline-flex items-center px-3 py-1 text-red-500 rounded-full gap-x-2 bg-red-100/60'>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M9 3L3 9M3 3L9 9'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>

        <h2 className='text-sm font-normal'>Canceled</h2>
      </div>
    );
  } else {
    return (
      <div className='inline-flex items-center px-3 py-1 text-orange-500 rounded-full gap-x-2 bg-orange-100/60'>
        <svg width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 6H9M20 21H19M19 21H5M19 21C19 18.4898 17.7877 16.1341 15.7451 14.675L12 12M5 21H4M5 21C5 18.4898 6.21228 16.1341 8.25493 14.675L12 12M20 3H19M19 3H5M19 3C19 5.51022 17.7877 7.86592 15.7451 9.32495L12 12M5 3H4M5 3C5 5.51022 6.21228 7.86592 8.25493 9.32495L12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <h2 className='text-sm font-normal'>Expiring</h2>
      </div>
    )
  }
};


const Plan = ({ item, dataKey, handleUpgrade, handlerCancel, accountType }) => {
  const [upgradables, setUpgradables] = useState([]);
  useEffect(() => {
    onUpgradeTo(item);
  }, [item]);

  const { user, prices } = useSelector((state) => state.auth.data || {});

  const copyLicense = (e, data) => {
    e.preventDefault();
    // var input = document.createElement('input');
    // document.body.appendChild(input);
    // input.value = data;
    // input.select();
    // // input.setSelectionRange(0, 99999); /*For mobile devices*/
    // document.execCommand('copy');
    // document.body.removeChild(input);
    navigator.clipboard.writeText(data);
    toast.success(`${data} copied to clipboard`, {
      position: 'top-right',
    });
  };

  const onUpgradeTo = (item) => {
    let upgradable_plans = [];

    const canUpgradeMap = {
      'Starter': ['starterp', 'premium'],
      'Starter+': ['premium'],
    }
    const canUpgradePlans = canUpgradeMap[item.plan_name] ?? [];
    for (let i = 0; i < canUpgradePlans.length; i++) {
      const plan = canUpgradePlans[i];
      upgradable_plans.push(prices[plan])
    }

    setUpgradables(upgradable_plans);
  };

  return (
    <tr className='bg-white border-b hover:bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {item.plan_name ? `${item.plan_name} ${item.hasSupport ? "(with Support)" : ""}` : 'Lifetime'}
      </td>

      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {Number(item.plan_amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).replace(/\.00$/, '')}{' '}
        / {item.plan_terms}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {/* {((item.subscription_status.toLowerCase() == 'active' || item.subscription_status.toLowerCase() == 'trialing')) ? ( // stripe can be cancelled and still be active with the cancel_at_period_end set to true
          <div className='inline-flex items-center px-3 py-1 rounded-full gap-x-2 text-orange-500 bg-orange-100/60'>
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6 1V7'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M6 8V9'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <h2 className='text-sm font-normal'>Expiring</h2>
          </div>
        ) : (
          <div className='inline-flex items-center px-3 py-1 text-red-500 rounded-full gap-x-2 bg-red-100/60'>
            <svg
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 3L3 9M3 3L9 9'
                stroke='currentColor'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>

            <h2 className='text-sm font-normal'>Canceled</h2>
          </div>
        )} */}
        <PlanStatus status={item.subscription_status.toLowerCase()} />
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {item.subscription_date_start
          ? new Date(item.subscription_date_start * 1000).toLocaleDateString()
          : 'NA'}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {item.subscription_date_end && (item.subscription_status != 'canceled' && item.subscription_status != 'cancelled')
          ? new Date(item.subscription_date_end * 1000).toLocaleDateString()
          : 'N/A'}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {item.pays_id == 'cc' ? 'Credit Card' : 'Paypal'}
      </td>
      <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <a
          target='_blank'
          className='pointer'
          onClick={(e) => copyLicense(e, item.license_key)}
          data-bs-container='body'
          data-bs-toggle='popover'
          data-bs-placement='top'
          data-bs-content={item.license_key}
          data-bs-id={dataKey}
        >
          {item.license_key.slice(0, 5)} **** &nbsp;
          <i className='fa fa-copy' />
        </a>
      </td>
      {/* <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <a
          target='_blank'
          className='pointer'
          onClick={(e) => copyLicense(e, item.license_key)}
          data-bs-container='body'
          data-bs-toggle='popover'
          data-bs-placement='top'
          data-bs-content={item.domains.join('<br/>')}
          data-bs-html='true'
          data-bs-id={dataKey}
        >
          {item.domains.length}/{item.license_number}
        </a>
      </td> */}
      {/* <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        <a className='pointer' onClick={onDownload}>
          <i className='fa fa-cloud-download' />
        </a>
      </td> */}
      {/* <td className='whitespace-nowrap px-6 py-3 text-sm font-medium text-gray-900'>
        {item.pays_id === 'cc' && (
          <a target='_blank' href={item.receipt_url}>
            <i className='fa fa-file-pdf-o' />
          </a>
        )}
        {item.pays_id === 'paypal' && (
          <a
            target='_blank'
            href='#'
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <i
              data-bs-container='body'
              data-bs-toggle='popover'
              data-bs-placement='top'
              data-bs-content='Please go to PayPal to download your receipt'
              className='text-muted fa fa-paypal'
            />
          </a>
        )}
        {item.pays_id === 'Manual' && (
          <a
            target='_blank'
            href='#'
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <i
              data-bs-container='body'
              data-bs-toggle='popover'
              data-bs-placement='top'
              data-bs-content='Please contact us to get your receipt'
              className='text-muted fa fa-comment'
            />
          </a>
        )}
      </td> */}
      <td className='whitespace-nowrap px-6 py-3 text-sm text-gray-900'>
        {((!item.noCollect && item.plan_name !== 'Support' && item.plan_name !== 'Premium' && item.subscription_status.toLowerCase() == 'active') || item.subscription_status.toLowerCase() == 'trialing') && (
          <select
            className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full'
            onChange={(e) => {
              const selectedValue = e.target.value;
              handleUpgrade({
                priceID: selectedValue, license_key: item.license_key
              })
              e.target.value = ''
            }}
          >
            <option value=''>Select Plan</option>
            {upgradables.map((selectedItem, id) => (
              <option
                className='py-2 h-11'
                key={id}
                data-key={id}
                value={selectedItem[item.pays_id == 'cc' ? 'stripe' : 'paypal']}
              >
                {selectedItem.title}
              </option>
            ))}
          </select>
        )}
      </td>
      <td className='whitespace-nowrap px-6 py-3 mt-3 text-sm font-medium text-gray-900'>
        {
          (!item.cancel_at_period_end && item.subID && (item.subscription_status.toLowerCase() == 'active' || item.subscription_status.toLowerCase() == 'trialing')) && ( // 
            (item.hasSupport && item.supportSubID && !item.supportSubCancelPeriodEnd) && (
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button className="flex gap-1 justify-center align-center items-center">
                      Cancel
                      <svg width="24" height="24" className={`fill-none ${open ? 'rotate-90 transform' : ''}`} viewBox='0 0 24 24'>
                        <path xmlns="http://www.w3.org/2000/svg" id="Vector" d="M10 8L14 12L10 16" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </Popover.Button>
                    <Popover.Panel className="absolute z-[500] left-1/2 mt-1 -translate-x-1/2 transform min-w-[200px]">
                      <div className="rounded-lg overflow-visible shadow-lg bg-white p-0.5">
                        <div className="flex items-center gap-2 m-1.5 rounded-md p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5"
                          onClick={(e) => {
                            e.preventDefault();
                            handlerCancel(item.license_key, "support", item.plan_name, Boolean(item.addon_id));
                          }}>
                          Support only
                        </div>
                        <div className="flex items-center gap-2 m-1.5 rounded-md p-2.5 text-sm cursor-pointer focus:ring-0 hover:bg-black/5"
                          onClick={(e) => {
                            e.preventDefault();
                            handlerCancel(item.license_key, "full", item.plan_name, Boolean(item.addon_id));
                          }}>
                          Full subscription
                        </div>
                      </div>
                    </Popover.Panel>
                  </>
                )}

              </Popover>
            ) || (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  handlerCancel(item.license_key, "full", item.plan_name, Boolean(item.addon_id));
                }}
                className='cursor-pointer'
              >
                Cancel
              </div>
            )

          )
        }

      </td>
    </tr>
  );
};

export default Plan;
