import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import { chatAPI } from '../features/chat/chatAPI';
import chatReducer from '../features/chat/chatSlice';
import { myAccountAPI } from '../features/myAccount/myAccountAPI';
import { surveyAPI } from '../features/survey/surveyAPI';
import planReducer from "../features/plan/planSlice"
import { planAPI } from "../features/plan/planAPI"
import { paymentAPI } from '../features/payment/paymentAPI';
import { gcildAPI } from '../features/gcild/gcildAPI';
import couponReducer from '../features/coupon/couponSlice';
import { couponAPI } from '../features/coupon/couponAPI';
import { knowledgeBaseAPI } from '../features/knowledgeBase/knowledgeBaseAPI';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    plan: planReducer, 
    coupon: couponReducer, 
    [chatAPI.reducerPath]: chatAPI.reducer,
    [myAccountAPI.reducerPath]: myAccountAPI.reducer,
    [surveyAPI.reducerPath]: surveyAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [planAPI.reducerPath]: planAPI.reducer,
    [gcildAPI.reducerPath]: gcildAPI.reducer,
    [couponAPI.reducerPath]: couponAPI.reducer,
    [knowledgeBaseAPI.reducerPath]: knowledgeBaseAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(chatAPI.middleware).concat(myAccountAPI.middleware).concat(surveyAPI.middleware).concat(paymentAPI.middleware).concat(planAPI.middleware).concat(gcildAPI.middleware).concat(couponAPI.middleware).concat(knowledgeBaseAPI.middleware),
  devTools: true,
});
