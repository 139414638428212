import { useEffect, useState } from 'react'
import { useGetActiveCampaignsQuery } from '../../features/coupon/couponAPI';
import { useDispatch } from 'react-redux';
import { openCampaignModal } from '../../features/coupon/couponSlice';


const useActiveCampaign = ({type = "banner"}) => {
    const { data: campaigns } = useGetActiveCampaignsQuery();
    const [campaign, setCampaign] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveCampaign();
    }, [campaigns]);

    const setActiveCampaign = () => {
        if (!campaigns || campaigns.length === 0) {
            return;
        }
        const closedCampaigns = JSON.parse(localStorage.getItem('closedCampaigns')) || [];
        const filteredCampaigns = campaigns.filter(campaign => !closedCampaigns.includes(campaign.campaign_key) && campaign.promo_location === type);
        const activeCampaign = filteredCampaigns.length ? filteredCampaigns[0] : null;
        setCampaign(activeCampaign);
    };

    const handleCampaignHide = (event) => {
        event.stopPropagation();
        if (!campaign) {
            return;
        }
        const closedCampaigns = JSON.parse(localStorage.getItem('closedCampaigns')) || [];
        closedCampaigns.push(campaign.campaign_key);
        localStorage.setItem('closedCampaigns', JSON.stringify(closedCampaigns));
        setCampaign(null);
        setActiveCampaign();
    };

    const handleCampaignClick = () => {
        if (!campaign) {
            return;
        }
        dispatch(openCampaignModal(campaign));
    };

    return {
        campaign,
        handleCampaignHide,
        handleCampaignClick
    };
};

export default useActiveCampaign;