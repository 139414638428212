import { createApi } from '@reduxjs/toolkit/query/react';
import { amplifyBaseQuery } from '../../store/utils';

export const couponAPI = createApi({
    reducerPath: 'couponAPI',
    baseQuery: amplifyBaseQuery,
    tagTypes: ['Coupon'],
    endpoints: (builder) => ({
        validateCoupon: builder.mutation({
            query: (data) => ({
                url: '/coupon',
                method: 'POST',
                data: {
                    action: "validateCoupon",
                    coupon: data.coupon,
                },
                useAuth: false,
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        }),
        getActiveCampaigns: builder.query({
            query: () => ({
                url: '/coupon',
                method: 'POST',
                data: {
                    action: "getActiveCampaigns",
                },
                useAuth: false,
            }),
            transformErrorResponse: (response) => {
                return response?.response?.data?.message || "An error occurred";
            },
        })
    }),
});

export const {
    useValidateCouponMutation,
    useGetActiveCampaignsQuery
} = couponAPI;