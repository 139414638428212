import React from "react";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
function Toast() {
  const contextClass = {
    success: "bg-[#0398ed]",
    error: "bg-[#e74c3c]",
  };

  const windowWidth = useWindowWidth();
  return (
    <div>

      <ToastContainer
        className={"z-[1000] pt-2 w-screen px-2 md:px-0 md:!w-auto"}
        toastClassName={(context) =>
          contextClass[context?.type || "default"] + " opacity-90 relative flex p-1 min-h-10 rounded-md justify-between mb-4"
        }
        position={ windowWidth < 768 ? 'bottom-center' : 'bottom-left'}
        hideProgressBar={true}
        theme="colored"
      />
    </div>
  );
}


function useWindowWidth() {
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== 'undefined' ? window.innerWidth : undefined
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;

    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}
export default Toast;