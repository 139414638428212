import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { removeQString } from '../../js/params';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreData } from '../../store/auth';
import { useNavigate } from 'react-router-dom';

const validateSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('This field is required'),
  password: Yup.string()
    .required('This field is required')
    .min(8, 'Pasword must be 8 or more characters'),
  confirmPassword: Yup.string().when('password', (password, field) => {
    if (password) {
      return field
        .required('The passwords do not match')
        .oneOf([Yup.ref('password')], 'The passwords do not match');
    }
  }),
});

const SignUp = () => {
  const Cookies = window.Cookies;
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showSignup, flowSelected, quantity, product_price, showPayment } =
    useSelector((state) => state.auth.data || {});

  const onLogin = (event) => {
    event.preventDefault();
    dispatch(
      setStoreData({
        data: {
          showLogin: true,
          showSignup: false,
        },
      })
    );
  };

  return (
    showSignup && (
      <>
        <div className='handl-modal pt-[40px] lg:w-[700px] md:w-[500px] w-[400px] fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-[10001]'>
          <div className='text-center mb-[10px]'>
            Hi there 👋 <br />
            Please create an account or{' '}
            <a href='#' onClick={onLogin} className='text-[#1972f5]'>
              Login
            </a>{' '}
            to access your downloads!{' '}
          </div>
          <div className='pl-[80px] pr-[80px]'>
            <Formik
              initialValues={{
                firstName: '',
                email: '',
                password: '',
                confirmPassword: '',
              }}
              validationSchema={validateSchema}
              onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const { email, firstName, password } = values;
                try {
                  console.log('here', Cookies);
                  // eslint-disable-next-line no-undef
                  console.log('here', HandL?.getAll())
                  const user = await Auth.signUp({
                    username: email,
                    password: password,
                    attributes: {
                      given_name: firstName,
                      middle_name: '',
                      'custom:utm_campaign': Cookies.get('utm_campaign') ?? 'NA',
                      'custom:utm_medium': Cookies.get('utm_medium') ?? 'NA',
                      'custom:utm_term': Cookies.get('utm_term') ?? 'NA',
                      'custom:utm_content': Cookies.get('utm_content') ?? 'NA',
                      'custom:utm_source': Cookies.get('utm_source') ?? 'NA',
                      'custom:ip': Cookies.get('handl_ip') ?? 'NA',
                      'custom:user_agent': Cookies.get('user_agent') ?? 'NA',
                      'custom:_fbc': Cookies.get('_fbc') ?? 'NA',
                      'custom:_fbp': Cookies.get('_fbp') ?? 'NA',
                      'custom:gclid': Cookies.get('gclid') ?? 'NA',
                      'custom:fbclid': Cookies.get('fbclid') ?? 'NA',
                      'custom:twclid': Cookies.get('twclid') ?? 'NA',
                      'custom:handl_url_new': Cookies.get('handl_url') ?? 'NA',
                      'custom:handl_landing_p_new': Cookies.get('handl_landing_page') ?? 'NA',
                      'custom:handl_ref_new': Cookies.get('handl_ref') ?? 'NA',
                      'custom:handl_org_ref_new': Cookies.get('handl_original_ref') ?? 'NA',
                      'custom:gaclientid': Cookies.get('gaclientid') ?? 'NA',
                    },
                  });
                  dispatch(
                    setStoreData({
                      data: {
                        showSignup: false,
                      },
                    })
                  );
                  const { gtag, fbq, rdt, twq } = window;
                  if (flowSelected === 'paid') {
                    localStorage.setItem(
                      'tmpUser',
                      JSON.stringify({
                        email,
                        first_name: firstName,
                      })
                    );
                    dispatch(
                      setStoreData({
                        data: {
                          isAuthenticated: true,
                          tmpUser: {
                            first_name: firstName,
                            email,
                          },
                        },
                      })
                    );
                    
                    gtag?.('event', 'view_item', {
                      items: [
                        {
                          id: 'HandL002',
                          name: 'HandL UTM Grabber V3',
                          brand: 'HandL',
                          category: 'WordPress Plugins',
                          variant: 'V3',
                          list_position: 2,
                          quantity,
                          price: `${product_price}.00`,
                        },
                      ],
                      email,
                    });
                    
                    navigate("/infos/subscriptions")
                  } else {
                    gtag?.('event', 'view_item', {
                      items: [
                        {
                          id: 'HandL001',
                          name: 'HandL UTM Grabber V2',
                          brand: 'HandL',
                          category: 'WordPress Plugins',
                          variant: 'V2',
                          list_position: 1,
                          quantity: quantity,
                          price: '0.00',
                        },
                      ],
                      email,
                    });
                  }

                  gtag?.('event', 'generate_lead', {
                    email,
                  });
                  // Event snippet for Subscribed For Free conversion page

                  gtag?.('event', 'conversion', {
                    send_to: 'AW-604754791/9r9kCK-XstsBEOemr6AC',
                  });

                  fbq?.('track', 'Lead', { currency: 'USD', value: 1 });
                
                  twq?.('event', 'tw-nytpn-onjy4', {
                    value: '1',
                    currency: 'USD',
                    status: 'completed',
                    // conversion_id: 'uniqueid',
                    email_address: email,
                  });

                  rdt('track', 'Lead', {
                    currency: 'USD',
                    value: 1,
                  });

                  if (showPayment) {
                    navigate('/payment');
                  }

                  // console.log("triggered...")
                } catch (error) {
                  console.log(error);
                  console.log(values);
                  setErrMsg(error.message);
                  setLoading(false);
                  resetForm();
                  dispatch(
                    setStoreData({
                      data: {
                        // showSignup: false,
                        showPayment: false,
                        selected_plan: null,
                      },
                    })
                  );
                }
                setLoading(false);
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className='form-group'>
                    <Field
                      type='text'
                      name='firstName'
                      className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      placeholder='First Name'
                    />
                    {touched.firstName && errors.firstName && (
                      <div className='text-red-400 mt-2'>
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className='form-group'>
                    <Field
                      type='email'
                      name='email'
                      className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      placeholder='Email address'
                    />
                    {touched.email && errors.email && (
                      <div className='text-red-400 mt-2'>{errors.email}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <Field
                      type='password'
                      name='password'
                      className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      placeholder='Password'
                    />
                    {touched.password && errors.password && (
                      <div className='text-red-400 mt-2'>{errors.password}</div>
                    )}
                  </div>
                  <div className='form-group'>
                    <Field
                      type='password'
                      name='confirmPassword'
                      className='g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5'
                      placeholder='Confirm Password'
                    />
                    {touched.confirmPassword && errors.confirmPassword && (
                      <div className='text-red-400 mt-2'>
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                  {errMsg && errMsg !== '' && (
                    <p className='text-red-400 mt-2'>{errMsg}</p>
                  )}
                  <div className='form-group'>
                    <button
                      type='submit'
                      className='btn btn-default btn-block btn-sm bg-[#0084ff] w-full'
                    >
                      {!loading ? (
                        <span>Register</span>
                      ) : (
                        <i className='fa fa-spin fa-spinner' />
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div
          className='absolute w-[100%] h-[100%] bg-black/50 top-[-100px] left-0 z-[10000]'
          onClick={() =>
            dispatch(
              setStoreData({
                data: {
                  showSignup: false,
                },
              })
            )
          }
        ></div>
      </>
    )
  );
};

export default SignUp;
